<template>
  <div class="content">
    <top-title>
      <span slot="left">秘钥管理</span>
      <span slot="right">秘钥管理</span>
    </top-title>
    <el-row class="part1" type="flex" justify="space-between">
      <el-col :span="24" :md="24" class="col-md9">
        <div class="data border">
          <div class="inner layout-flex">
            <div class="col-name">SecretId</div>
            <div class="col-content">
              <span>
                {{
                secretId != ""
                ? secretId.substr(0, 3) + "****" + secretId.substr(29, 31)
                : ""
                }}
              </span>
              <!-- <span>{{ secretId }}</span> -->
              <fortress-copy :copyData="secretId"></fortress-copy>
            </div>
          </div>
          <div class="inner layout-flex">
            <div class="col-name">SecretKey</div>
            <!-- <div class="col-content" v-if="keyVisible">
              <span>****</span>
              <a
                style="
                  text-decoration: none;
                  margin-left: 10px;
                  color: #409eff;
                  font-size: 14px;
                "
                href="#"
                @click="getKey"
              >
                获取</a
              >
            </div>
            <div v-else class="col-content">
              <span>{{ secretKey }}</span>
              <fortress-copy :copyData="secretKey"></fortress-copy>
            </div>-->

            <div class="col-content">
              <span>
                {{
                secretKey != ""
                ? secretKey.substr(0, 3) + "****" + secretKey.substr(29, 31)
                : ""
                }}
              </span>
            </div>
            <div class="col-content">
              <fortress-copy :copyData="secretKey"></fortress-copy>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      title="获取秘钥KEY"
      :visible.sync="phoneVisible"
      :close-on-click-modal="false"
      width="660px"
    >
      <template>
        <div>
          <span style="font-size: 10px; color: #b0bec5; display: inline-block">手机</span>
          <span style="font-size: 10px; margin-left: 60px">{{ basic.phone }}</span>
        </div>
        <div style="margin-top: 20px">
          <span
            style="
              font-size: 10px;
              color: #b0bec5;
              width: 85px;
              display: inline-block;
            "
          >
            短信验证码
            <span style="color: red">*</span>
          </span>
          <el-input
            style="
              width: 50%;
              height: 36px;
              display: inline-block;
              margin-right: 7px;
            "
            placeholder="6 位数字验证码"
            type="text"
            v-model="phoneDialog.code"
          ></el-input>
          <el-button type="primary" @click="sendCode(basic.phone)">发送验证码</el-button>
        </div>
        <div style="margin-top: 20px; text-align: center">
          <el-button type="primary" @click="judgeCode">确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
import { secretInfo } from "@/api/api.js";
import { sendSms, checkCode } from "@/api/index.js";
export default {
  data () {
    return {
      secretId: "",
      secretKey: "",
      phoneVisible: false,
      keyVisible: true,
      phoneDialog: {
        code: "",
      },
      basic: {
        phone: "",
      },
    };
  },
  created () {
    this.basic = JSON.parse(localStorage.getItem("userInfo"));
    this.queryInfo();
  },
  activated () {
    this.keyVisible = true;
  },
  components: {
    TopTitle,
  },

  computed: {},

  methods: {
    //获取秘钥信息
    queryInfo () {
      secretInfo().then((resp) => {
        if (resp.code == 0) {
          this.secretId = resp.data.secretId;
          this.secretKey = resp.data.secretKey;
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //获取key
    getKey () {
      this.phoneVisible = true;
    },
    //发送验证码
    sendCode (phone) {
      sendSms({ mobile: phone, type: 3 })
        .then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "验证码发送成功, 请注意查收!",
            });
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.$message({
            message: e.msg,
            type: "error",
          });
        });
      --this.time;
    },
    //判断校验码
    judgeCode () {
      if (!this.phoneDialog.code) {
        this.$message({
          message: "请填写验证码",
          type: "error",
        });
        return;
      }
      checkCode(this.basic.phone, this.phoneDialog.code, 3)
        .then((resp) => {
          if (resp.code == 0) {
            this.phoneVisible = false;
            this.keyVisible = false;
          } else {
            this.phoneDialog.code = "";
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.$message({
            message: e.msg,
            type: "error",
          });
        });
    },
    close () {
      this.phoneVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin tit($bottom: 15px) {
  margin-bottom: $bottom;
  font-size: 16px;
  color: #666;
  font-weight: bold;
}
@mixin layout-flex() {
  margin-bottom: 20px;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.content {
  width: 100%;
  .part1 {
    @include layout-flex();
    .col-md9 {
      margin-bottom: 20px;
    }
    .data {
      margin: 0 0 20px 0;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .col-name {
          margin-right: 20px;
          color: #999;
          font-size: 14px;
          width: 7%;
        }
        .col-content {
          span {
            font-size: 14px;
            color: #666;
            font-weight: bold;
          }
        }
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>